module.exports = [{
      plugin: require('../node_modules/gatsby-theme-contact/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Laura Norwood","short_name":"Laura Norwood","start_url":"/","background_color":"#F0E6E0","theme_color":"#9DAD9F","display":"standalone","icon":"src/images/icon_catbot.png","crossOrigin":"anonymous","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"8d822275848114ce97c532ef42c0da96"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/sketchbook/","/about/","/"]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://50863e41f2c94ac38144e896197d498f@o122225.ingest.sentry.io/4167682","release":"8.8.0","tracesSampleRate":0.8},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
